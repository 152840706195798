const types = {
  GENERATE_SURVEY_CODE_REQUEST: 'FORM/GENERATE_SURVEY_CODE_REQUEST',
  GENERATE_SURVEY_CODE_SUCCESS: 'FORM/GENERATE_SURVEY_CODE_SUCCESS',
  GENERATE_SURVEY_CODE_FAILURE: 'FORM/GENERATE_SURVEY_CODE_FAILURE',
  SUBMIT_APPLICATION_REQUEST: 'FORM/SUBMIT_APPLICATION_REQUEST',
  SUBMIT_APPLICATION_SUCCESS: 'FORM/SUBMIT_APPLICATION_SUCCESS',
  SUBMIT_APPLICATION_FAILURE: 'FORM/SUBMIT_APPLICATION_FAILURE',
  SUBMIT_SESSION_REVIEW_REQUEST: 'FORM/SUBMIT_SESSION_REVIEW_REQUEST',
  SUBMIT_SESSION_REVIEW_SUCCESS: 'FORM/SUBMIT_SESSION_REVIEW_SUCCESS',
  SUBMIT_SESSION_REVIEW_FAILURE: 'FORM/SUBMIT_SESSION_REVIEW_FAILURE',
  SET_CURRENT_STEP: 'FORM/SET_CURRENT_STEP',
  SET_FORM_ATTRIBUTE: 'FORM/SET_FORM_ATTRIBUTE',
  VALIDATE_REQUIRED_FIELD: 'FORM/VALIDATE_REQUIRED_FIELD',
  SET_OPTIONAL_FIELDS_STATUS: 'FORM/SET_OPTIONAL_FIELDS_STATUS',
  SET_OPTIONAL_FIELD_STATUS: 'FORM/SET_OPTIONAL_FIELD_STATUS',
};

export default types;
