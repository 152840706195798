import styled from "styled-components";

export const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  color: #001928;
`;
