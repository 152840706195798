const types = {
  GET_ALL_DATA_REQUEST: 'USER_DATA/GET_ALL_DATA_REQUEST',
  GET_ALL_DATA_SUCCESS: 'USER_DATA/GET_ALL_DATA_SUCCESS',
  GET_ALL_DATA_FAILURE: 'USER_DATA/GET_ALL_DATA_FAILURE',
  DOWNLOAD_ALL_DATA_REQUEST: 'USER_DATA/DOWNLOAD_ALL_DATA_REQUEST',
  DOWNLOAD_ALL_DATA_SUCCESS: 'USER_DATA/DOWNLOAD_ALL_DATA_SUCCESS',
  DOWNLOAD_ALL_DATA_FAILURE: 'USER_DATA/DOWNLOAD_ALL_DATA_FAILURE',
  DELETE_ALL_DATA_REQUEST: 'USER_DATA/DELETE_ALL_DATA_REQUEST',
  DELETE_ALL_DATA_SUCCESS: 'USER_DATA/DELETE_ALL_DATA_SUCCESS',
  DELETE_ALL_DATA_FAILURE: 'USER_DATA/DELETE_ALL_DATA_FAILURE',
};

export default types;
